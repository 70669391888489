import Cookies from 'js-cookie';
import React from 'react';
import Snowfall from 'react-snowfall';


interface SnowEffectProps {
  color: string | undefined;
}

const SnowEffect = (props: SnowEffectProps) => {
  const viewport = Cookies.get('viewport');

  return (
    <Snowfall
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        pointerEvents: 'none' // 不影响其他元素的操作
      }}
      color={props.color}
      snowflakeCount={viewport === "desktop" ? 48 : 24}
      speed={[0.5, 1]}
      wind={[-0.5, 0.5]}
      radius={[0.5, 3]}
    />
  );
};

export default SnowEffect;
